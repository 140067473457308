<template>
  <div class="ReferenceOperatorWorkMain">
    <div class="ReferenceOperatorWorkButtonWrapper">
      <v-btn color="#e21a1a" v-on:click="takeOrderReservation" depressed :loading="this.isReservationProcess" v-if="this.type === 'operator'">
        <v-icon color="white" left>mdi-arrow-right-bottom</v-icon>
        <span class="ReferenceOperatorWorkButtonText">ВЗЯТЬ В РАБОТУ</span>
      </v-btn>
      <v-btn color="#e21a1a" v-on:click="showOrderForKitchen" depressed v-if="type === 'kitchen'">
        <v-icon color="white" left>mdi-arrow-right-bottom</v-icon>
        <span class="ReferenceOperatorWorkButtonText">ПОСМОТРЕТЬ</span>
      </v-btn>
    </div>
    <v-dialog v-model="dialog" max-width="1280" persistent>
      <v-card v-if="this.isShowWindow" class="file-upload-card" outlined>
        <v-form ref="productForm" @submit.prevent="checkInput">
          <v-card-title class="pb-4">Заказ</v-card-title>
          <v-row>
            <v-col v-if="type === 'kitchen'">
              <v-card-title>Состав заказа</v-card-title>
              <v-row>
                <v-col class="d-flex mb-0">
                  <v-card-text>Позиция</v-card-text>
                  <v-card-text>Количество</v-card-text>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row v-for="item in editOrder.items" :key="item.token">
                <v-col class="d-flex mb-0 py-0">
                  <v-card-text>{{ item.nameRu }}</v-card-text>
                  <v-card-text>{{ item.ItemOrder.quantity }}</v-card-text>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-col>
            <v-col>
              <v-card-title>Персональные данные</v-card-title>
              <v-row v-for="item in getReferenceFields" :key="item.id">
                <v-col
                  class="d-flex mb-0 py-0"
                  v-if="item.type !== 'takeoutId' && item.type !== 'cafeId' && item.type !== 'clientId' && item.type !== 'statusId'"
                >
                  <v-card-text> {{ item.name }}: </v-card-text>
                  <v-card-text> {{ editOrder[item.in] }}</v-card-text>
                </v-col>
                <v-col class="d-flex mb-0 py-0" v-if="item.type === 'takeoutId'">
                  <!-- <v-card-text> {{ item.name }}: </v-card-text> -->
                  <!-- <v-card-text> {{ takeoutsName }}</v-card-text> -->
                </v-col>
                <v-col class="d-flex mb-0 py-0" v-if="item.type === 'cafeId'">
                  <!-- <v-card-text> {{ item.name }}: </v-card-text> -->
                  <!-- <v-card-text> {{ editOrder.cafe.nameRu }}</v-card-text> -->
                </v-col>
                <v-col class="d-flex mb-0 py-0" v-if="item.type === 'statusId'">
                  <v-card-text> {{ item.name }}: </v-card-text>
                  <v-card-text> {{ statusName }}</v-card-text>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex mb-0 py-0">
                  <v-card-text>Курьер:</v-card-text>
                </v-col>
                <v-col class="d-flex mb-0 py-0">
                  <v-autocomplete
                    v-if="this.type === 'operator'"
                    background-color="white"
                    class="mx-2"
                    dense
                    outlined
                    v-model="courierToken"
                    :items="courierList"
                    item-text="fio"
                    item-value="token"
                  />
                  <v-card-text v-if="this.type === 'kitchen'">{{ courier }}</v-card-text>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-card-actions class="mb-2 d-flex flex-wrap">
            <v-btn v-if="editOrder.status.id !== 100" text class="mr-auto mx-2 text-none my-1" color="primary" @click="openConfirmDialog">ОТМЕНИТЬ ЗАКАЗ</v-btn>
            <v-btn v-if="this.type === 'operator'" class="white--text my-1" depressed color="#e21a1a" @click="setCourierClick">назначить курьера</v-btn>
            <v-btn
              v-if="this.type === 'kitchen'"
              :disabled="editOrder.status.id !== 11 && this.type === 'kitchen'"
              class="white--text my-1"
              depressed
              color="#e21a1a"
              @click="acceptOrderClick"
              >ПРИНЯТЬ</v-btn
            >
            <v-btn
              v-if="this.type === 'kitchen'"
              :disabled="editOrder.status.id !== 23 && this.type === 'kitchen'"
              class="white--text my-1"
              depressed
              color="#e21a1a"
              @click="readyOrderClick"
              >ГОТОВ К ВЫДАЧЕ</v-btn
            >
            <v-btn :disabled="editOrder.status.id !== 30 && this.type === 'kitchen'" class="white--text my-1" color="#e21a1a" @click="completeOrderClick"
              >ЗАВЕРШИТЬ</v-btn
            >
            <v-btn text class="mx-2 text-none my-1" color="grey darken-3" @click="closeDialog">ОТМЕНА</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="cryptoProErrorDialog" max-width="1024" persistent>
      <v-card class="file-upload-card pa-4" outlined>
        <div>Не удалось получить сертификат из CryptoPro. Проверьте, что плагин CryptoPro установлен и сертификат в наличии.</div>
        <v-btn type="submit" depressed color="#e21a1a" class="white--text mr-3 text-RussianRail" v-on:click="closeCryptoProErrorDialog">Закрыть</v-btn>
      </v-card>
    </v-dialog>
    <v-dialog v-model="cancelReference" max-width="1024" persistent>
      <v-card class="file-upload-card pa-4" outlined>
        <div>
          Вы действительно хотите отменить заявку? <br />
          Обязанность возврата денег и связь с клиентом ложится на оператора
        </div>
        <!-- <v-text-field outlined :label="editOrder.mail" v-model="cancelEmail"></v-text-field> -->
        <v-text-field outlined label="Причина" :rules="rules.notEmpty" v-model="cancelReason"></v-text-field>
        <div v-if="this.isCancelError">Не удалось отменить заявку: {{ this.cancelMessage }}</div>
        <v-card-actions class="d-flex flex-row-reverse">
          <v-btn type="submit" depressed class="mr-3 text-RussianRail" v-on:click="closeCancelDialog">Вернуться к заполнению</v-btn>
          <v-btn
            type="submit"
            depressed
            color="#e21a1a"
            :loading="this.isCancelLoading"
            class="white--text mr-3 text-RussianRail"
            v-on:click="confirmCancelReference"
            >Отменить заявку</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="notification">
      <Notification />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ReferenceHelper from '@/helpers/reference/reference.helper';
import moment from 'moment';
import Notification from '@/components/Notification';
export default {
  props: {
    order: {
      required: true
    },
    type: {
      required: true
    },
    cafe: {
      required: true
    }
  },
  data() {
    return {
      editOrder: this.order,
      dialog: false,
      validate: true,
      cryptoProErrorDialog: false,
      loading: false,
      datePickerMenu_1: false,
      datePickerMenu_2: false,
      datePickerMenu_3: false,
      notEmptyRules: [(v) => !!v || 0 || 'Обязательное поле'],
      certsApi: null,
      certsList: null,
      cert: null,
      isShowWindow: false,
      isReservationProcess: false,
      returnCheckBox: false,
      returnTicket: false,
      cancelReference: false,
      cancelReason: '',
      cancelEmail: '',
      courierToken: this.order.courier !== null ? this.order.courier.token : '',
      rules: {
        emailForm: [
          (value) => {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || value.trim() == '' || 'Неверный формат';
          }
        ],
        numberForm: [(v) => (v.length > 10 && v.length < 12) || v.trim() == '' || 'Проверьте правильность указанного номера'],
        checkTrainNumber: [
          (value) => {
            const pattern = /^((\d{3})+([А-Я]))$/;
            return pattern.test(value) || value.trim() == '' || 'Неверный формат (Пример: 001А)';
          }
        ],
        wordForm: [
          (value) => {
            const pattern = /[ЁёА-я,A-Za-z .-]+$/;
            return pattern.test(value) || value.trim() == '' || 'Неверный формат';
          }
        ],
        timeForm: [
          (value) => {
            const pattern = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
            return pattern.test(value) || 'Неверный формат времени (ЧЧ:ММ)';
          }
        ],
        notEmpty: [(v) => !!v || 0 || 'Обязательное поле'],
        ticketForm: [(v) => (v.length >= 13 && v.length <= 14) || 'Проверьте правильность указанного номера. 13 или 14 цифр'],
        carForm: [(v) => (v.length >= 1 && v.length <= 2) || 'Проверьте правильность указанного номера'],
        seatAvailableForm: [(v) => (v.length >= 0 && v.length <= 3) || 'Проверьте правильность указанного числа'],
        carFormFourSymbols: [
          (value) => {
            const pattern = /^((\d{3,}))$/;
            return pattern.test(value) || value.trim() == '' || 'Неверный формат';
          }
        ],
        priceSymbols: [
          (value) => {
            const pattern = /^\d+(?:\.\d+)?$/;
            return (pattern.test(value) && value !== 0) || 'Неверный формат';
          }
        ]
      }
    };
  },
  components: { Notification },
  computed: {
    cafeName() {
      return this.cafe;
    },
    // courierToken() {
    //   if (this.editOrder.courier === null) return '';
    //   return this.editOrder.courier.token;
    // },
    courier() {
      if (this.editOrder.courier === null) return '';
      return this.editOrder.courier.fio;
    },
    takeoutsName() {
      return this.editOrder.takeout.nameFullRu;
    },
    statusName() {
      return ReferenceHelper.getOrderStatus(this.editOrder.status.id);
    },
    getReferenceFields() {
      const fields = ReferenceHelper.getFields();
      return { ...fields[1] };
    },
    ...mapGetters('reference', ['isPdfLoading']),
    ...mapGetters('reference', ['isPdfError']),
    ...mapGetters('reference', ['pdfResult']),
    ...mapGetters('reference', ['pdfData']),
    ...mapGetters('reference', ['pdfMessage']),
    ...mapGetters('reference', ['isSendPdfLoading']),
    ...mapGetters('reference', ['isSendPdfError']),
    ...mapGetters('reference', ['sendPdfResult']),
    ...mapGetters('reference', ['sendPdfData']),
    ...mapGetters('reference', ['sendPdfMessage']),
    ...mapGetters('order', ['isCancelLoading']),
    ...mapGetters('order', ['isCancelError']),
    ...mapGetters('order', ['isCancelComplete']),
    ...mapGetters('order', ['cancelResult']),
    ...mapGetters('order', ['cancelMessage']),
    ...mapGetters('order', ['isAcceptLoading']),
    ...mapGetters('order', ['isAcceptError']),
    ...mapGetters('order', ['isAcceptComplete']),
    ...mapGetters('order', ['acceptResult']),
    ...mapGetters('order', ['acceptMessage']),
    ...mapGetters('order', ['isReadyLoading']),
    ...mapGetters('order', ['isReadyError']),
    ...mapGetters('order', ['isReadyComplete']),
    ...mapGetters('order', ['readyResult']),
    ...mapGetters('order', ['readyMessage']),
    ...mapGetters('order', ['isCompleteLoading']),
    ...mapGetters('order', ['isCompleteError']),
    ...mapGetters('order', ['isCompleteComplete']),
    ...mapGetters('order', ['completeResult']),
    ...mapGetters('order', ['completeMessage']),
    ...mapGetters('courier', ['courierList']),
    ...mapGetters('order', ['setCourierMessage'])
  },
  methods: {
    getStatus() {
      ReferenceHelper.getOrderStatus();
    },
    async confirmCancelReference() {
      try {
        const data = { id: this.editOrder.id, message: this.cancelReason };
        if (this.cancelEmail !== undefined && this.cancelEmail !== null && this.cancelEmail.length > 0) {
          data.mail = this.cancelEmail;
        }
        await this.cancelOrder(data);
        this.closeCancelDialog();
        this.closeDialog();
        this.showNotification('Успешно', 'success', '2000', 'mdi-check');
      } catch (error) {
        this.showErrorNotification(`Возникла ошибка: ${this.cancelMessage}`, 'red', '2000', 'mdi-alert-circle-outline');
      }
    },
    closeDialog() {
      this.dialog = false;
      this.editOrder = this.order;
      if (this.type === 'kitchen') {
        this.fetchOrdersForKitchen();
      } else {
        this.fetchReferenceList();
      }
      this.clearOrderTimer();
    },
    closeCryptoProErrorDialog() {
      // this.cryptoProErrorDialog = false;
    },
    closeCancelDialog() {
      this.cancelReference = false;
    },
    openConfirmDialog() {
      this.cancelReference = true;
    },
    setCourierClick() {
      this.setCourierOrder({ id: this.editOrder.id, courierToken: this.courierToken })
        .then(() => {
          this.closeDialog();
          this.showNotification('Успешно', 'success', '2000', 'mdi-check');
        })
        .catch(() => {
          this.showErrorNotification(`Возникла ошибка: ${this.setCourierMessage}`, 'red', '2000', 'mdi-alert-circle-outline');
        });
    },
    acceptOrderClick() {
      this.acceptOrder({ id: this.editOrder.id })
        .then(() => {
          this.closeDialog();
          this.showNotification('Успешно', 'success', '2000', 'mdi-check');
        })
        .catch(() => {
          this.showErrorNotification(`Возникла ошибка: ${this.acceptMessage}`, 'red', '2000', 'mdi-alert-circle-outline');
        });
    },
    readyOrderClick() {
      this.readyOrder({ id: this.editOrder.id })
        .then(() => {
          this.closeDialog();
          this.showNotification('Успешно', 'success', '2000', 'mdi-check');
        })
        .catch(() => {
          this.showErrorNotification(`Возникла ошибка: ${this.readyMessage}`, 'red', '2000', 'mdi-alert-circle-outline');
        });
    },
    completeOrderClick() {
      this.completeOrder({ id: this.editOrder.id })
        .then(() => {
          this.closeDialog();
          this.showNotification('Успешно', 'success', '2000', 'mdi-check');
        })
        .catch(() => {
          this.showErrorNotification(`Возникла ошибка: ${this.completeMessage}`, 'red', '2000', 'mdi-alert-circle-outline');
        });
    },
    createEditingObject() {
      this.editOrder = JSON.parse(JSON.stringify(this.order));
      // this.editOrder = Object.assign({}, this.order);
    },
    async takeOrderReservation() {
      try {
        this.editOrder = JSON.parse(JSON.stringify(this.order));
        this.isReservationProcess = true;
        await this.takeOrder({ id: this.order.id });
        this.isShowWindow = true;
        this.dialog = true;
        await this.setOrderTimer(moment());
      } catch (ex) {
        this.isShowWindow = false;
        this.dialog = false;
        await this.clearOrderTimer();
      } finally {
        this.isReservationProcess = false;
      }
    },
    showOrderForKitchen() {
      this.editOrder = JSON.parse(JSON.stringify(this.order));
      this.isShowWindow = true;
      this.dialog = true;
    },
    showNotification(message, color, timer, icon) {
      this.showSnack({
        message,
        color,
        timer,
        icon
      });
    },
    showErrorNotification(message, color, timer, icon) {
      this.showSnack({
        message,
        color,
        timer,
        icon
      });
    },
    ...mapActions('reference', ['takeOrder']),
    ...mapActions('reference', ['confirmAndGetPdf']),
    ...mapActions('reference', ['confirmAndSendPdf']),
    ...mapActions('reference', ['fetchReferenceList']),
    ...mapActions('reference', ['addSignToPdf']),
    ...mapActions('reference', ['deletePdfData']),
    ...mapActions('reference', ['setOrderTimer']),
    ...mapActions('reference', ['clearOrderTimer']),
    ...mapActions('order', ['acceptOrder']),
    ...mapActions('order', ['readyOrder']),
    ...mapActions('order', ['completeOrder']),
    ...mapActions('order', ['cancelOrder']),
    ...mapActions('order', ['setCourierOrder']),

    ...mapActions('kitchen', ['fetchOrdersForKitchen']),
    ...mapActions('notification', ['showSnack'])
  },

  mounted() {
    this.createEditingObject();
  }
};
</script>
<style>
.notification {
  position: fixed;
  bottom: 5px;
  right: 5px;
  z-index: 999;
}
.RowField {
  padding-left: 15px;
}
.custom-label-color .v-label .theme--light.v-label {
  color: rgb(22, 216, 241);
  opacity: 1;
}
.OperatorWorkContainer > .RowField {
  /* flex: 1 1 33%; grow | shrink | basis */
  /* height: 100px; */
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 30%;
}
.OperatorWorkContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.ButtonsGroup {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-end;
}
.CancelButton {
  margin-right: auto;
}

.file-upload-card {
  font-family: RussianRail;
}

.OperatorWorkField {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 20px;
}

.ReferenceOperatorWorkMain {
  font-weight: 400;
}

.ReferenceOperatorWorkButtonIcon {
  margin-right: 10px;
}

.ReferenceOperatorWorkButtonText {
  color: white;
}

.ReferenceOperatorWorkButtonCancelText {
  color: gray;
}

.ReferenceOperatorWorkFieldName {
  display: flex;
  justify-content: center;
  font-size: large;
  margin-bottom: 10px;
  padding: 0;
  color: black;
}

.ReferenceOperatorWorkBlockSpacer {
  margin-top: 20px;
}

.ReferenceOperatorWorkAlertBlock {
  margin-top: 20px;
  color: #c00000;
}

.ReferenceOperatorWorkPanelHeaderWarning {
  font-weight: bold;
  color: #ed7d31;
}

.ReferenceOperatorWorkPanelHeaderError {
  font-weight: bold;
  color: #c00000;
}

.ReferenceOperatorWorkHintText {
  color: #606060;
}

.ReferenceOperatorWorkPicker {
  border-radius: 5px;
  color: white;
  font-weight: 400;
  box-shadow: 0 2px 2px #8eaadb;
  border: solid 1px #2f5496;
  margin: 20px 0 10px 0;
  padding: 2px;
}

.ReferenceOperatorWorkButtonWrapper {
  border: solid 1px #e21a1a;
  border-radius: 6px;
  width: min-content;
}

.ReferenceOperatorWorkButtonCancelWrapper {
  border: solid 1px gray;
  border-radius: 6px;
  margin-left: 20px;
}

.ReferenceOperatorWorkFileText {
  color: black;
}

.ReferenceOperatorWorkCheckResult {
  display: flex;
  margin: 20px 0 0 0;
}

.ReferenceOperatorWorkProcessList {
  display: flex;
  margin: 0 40px 0 0;
}

.ReferenceOperatorWorkProcessListNumber {
  font-size: 42px;
  color: #2f5496;
  line-height: 1;
}

.ReferenceOperatorWorkProcessListText {
  font-size: 14px;
  color: #8eaadb;
  margin-left: 16px;
}

.ReferenceOperatorWorkProcessListNumberTotal {
  font-size: 42px;
  color: black;
  line-height: 1;
}

.ReferenceOperatorWorkProcessListTextTotal {
  font-size: 14px;
  color: #a6a6a6;
  margin-left: 16px;
}

.ReferenceOperatorWorkProcessListNumberWarning {
  font-size: 42px;
  color: #ed7d31;
  line-height: 1;
}

.ReferenceOperatorWorkProcessListTextWarning {
  font-size: 14px;
  color: #f4b083;
  margin-left: 16px;
}

.ReferenceOperatorWorkProcessListNumberError {
  font-size: 42px;
  color: #c00000;
  line-height: 1;
}

.ReferenceOperatorWorkProcessListTextError {
  font-size: 14px;
  color: #ff4b4b;
  margin-left: 16px;
}

.ReferenceOperatorWorkTableRowWarningFirst {
  background-color: #fbe4d5;
  padding: 0 6px 0 6px;
}

.ReferenceOperatorWorkTableRowWarningSecond {
  background-color: white;
  padding: 0 6px 0 6px;
}

.ReferenceOperatorWorkTableRowErrorFirst {
  background-color: #ffc5c5;
  padding: 0 6px 0 6px;
}

.ReferenceOperatorWorkTableRowErrorSecond {
  background-color: white;
  padding: 0 6px 0 6px;
}

.ReferenceOperatorWorkTableHeaderWarning {
  color: black;
  font-weight: bold;
  padding: 0 6px 0 6px;
  border-bottom: solid 1px #ed7d31;
}

.ReferenceOperatorWorkTableHeaderError {
  color: black;
  font-weight: bold;
  padding: 0 6px 0 6px;
  border-bottom: solid 1px #c00000;
}

.VCardTitle {
  font-size: 0.8em !important;
  margin: 0;
  padding: 0;
}

v-col {
  padding: 0;
}
</style>
