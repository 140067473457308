<template>
  <div class="Reference">
    <NavBar />
    <h2>Интерфейс кухни</h2>
    <v-autocomplete label="Выберите кафе" outlined v-model="cafe" v-on:change="chooseCafe" :items="getCafes()"></v-autocomplete>
    <div class="ReferenceOperatorMainframe">
      <div class="ReferenceUpBoard">
        <!-- <div v-if="!this.isFetchOrdersLoading && !this.isFetchResultError && this.fetchOrdersData && this.fetchOrdersData.length > 0"> -->
        <div v-if="currentCafe !== null">
          <div class="ReferenceProcessList">
            <div class="ReferenceProcessListNumber">
              {{ this.currentCafe.length }}
            </div>
            <div class="VipProcessListText">
              {{ this.declOfNum(this.currentCafe.length, ['заказ', 'заказа', 'заказов']) }}
              <v-spacer />
              всего
            </div>
          </div>
        </div>
        <div v-if="this.isFetchOrdersLoading || this.isFetchResultError || (this.fetchOrdersData !== null && this.fetchOrdersData.length <= 0)">
          <v-chip label text-color="white" color="#0066A1">
            {{ this.loadProgress }}
          </v-chip>
        </div>
      </div>
      <div>
        <div>
          <ReferenceOperatorList v-if="currentCafe !== null" :referenceList="this.currentCafe" type="kitchen" />
        </div>
      </div>
    </div>
    <NotificationContainer />
  </div>
</template>

<script>
import helper from '@/helpers/reference/reference.helper';
import ReferenceOperatorList from '@/components/reference/ReferenceOperatorList';
import NavBar from '@/components/NavBar';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import NotificationContainer from '@/components/notification/NotificationContainer.vue';

export default {
  data() {
    return {
      search: null,
      interval: null,
      countCurrentType: null,
      cafe: ''
      // currentCafe: null
    };
  },
  components: {
    NotificationContainer,
    ReferenceOperatorList,
    NavBar
  },
  computed: {
    cafes() {
      let cafesArr = [];
      if (this.fetchOrdersData !== undefined && this.fetchOrdersData !== null) {
        this.fetchOrdersData.forEach((item) => {
          cafesArr.push(item);
        });
      }
      return JSON.parse(JSON.stringify(cafesArr));
    },
    currentCafe() {
      let cafe = null;
      if (this.cafe !== null && this.cafe.length > 0) {
        cafe = this.fetchOrdersData.find((curCafe) => curCafe.token === this.cafe).orders;
        console.log(cafe, 'CAFE');
      }
      return cafe;
    },
    loadProgress() {
      if (this.isFetchOrdersLoading) {
        return 'Загружаем список';
      } else if (this.isFetchResultError) {
        return 'Ошибка загрузки списка: ' + this.isFetchOrderErrorMessage;
      } else if (!this.fetchOrdersData || this.fetchOrdersData.length <= 0) {
        return 'Список пуст';
      } else return 'Количество записей: ' + this.fetchOrdersData.length;
    },
    ...mapGetters('kitchen', ['fetchOrdersData']),
    ...mapGetters('kitchen', ['isFetchOrdersLoading']),
    ...mapGetters('kitchen', ['isFetchResultError']),
    ...mapGetters('kitchen', ['isFetchOrderErrorMessage']),
    ...mapGetters('kitchen', ['orderListTimer'])
  },
  mounted() {
    this.updateOrdersList();
    this.interval = setInterval(() => {
      let isNeedToUpdate = false;
      try {
        if (this.orderListTimer === undefined || this.orderListTimer === null) {
          isNeedToUpdate = true;
        } else {
          if (this.orderListTimer.isBefore(moment().subtract(10, 'm'))) {
            isNeedToUpdate = true;
          }
        }
      } catch (ex) {
        console.log(ex);
        isNeedToUpdate = true;
      }
      if (isNeedToUpdate) {
        this.updateOrdersList();
      }
    }, 60000);
  },
  beforeDestroy() {
    if (this.interval !== null) {
      clearInterval(this.interval);
    }
  },
  methods: {
    chooseCafe() {
      this.currentCafe = JSON.parse(JSON.stringify(this.cafes.find((cafe) => this.cafe === cafe.token)));
    },
    getCafes() {
      let list = [];
      if (this.cafes !== undefined && this.cafes !== null && this.cafes.length >= 0) {
        for (let i = 0; i < this.cafes.length; i++) {
          list.push({ text: this.cafes[i].nameRu, value: this.cafes[i].token });
        }
      }
      return list;
    },
    declOfNum: helper.declOfNum,
    updateOrdersList() {
      this.fetchOrdersForKitchen();
    },
    ...mapActions('kitchen', ['fetchOrdersForKitchen'])
  }
};
</script>

<style>
.Reference {
  //background-color: blue;
}

.ReferenceUpBoard {
  min-height: 50px;
}

.ReferenceOperatorMainframe {
  //background-color: burlywood;
}

.ReferenceSearch {
  border-radius: 5px;

  color: white;

  font-weight: 400;

  box-shadow: 0 2px 2px #8eaadb;

  border: solid 1px #2f5496;

  margin: 20px 0 10px 0;

  padding: 2px;
}

.text-black input {
  color: black !important;
}

.ReferenceOperatorList {
  display: flex;

  align-items: center;

  margin: 20px 0 10px 0;

  font-weight: 500;
}

.ReferenceUploadButton {
  margin-left: auto;
}

.ReferenceProcessList {
  display: flex;
}

.ReferenceProcessListNumber {
  font-size: 50px;

  color: #e21a1a;

  padding-right: 10px;

  line-height: 1;

  font-weight: normal;
}

.ReferenceProcessListText {
  font-size: 14px;

  color: #8eaadb;

  margin-left: 16px;
}
</style>
