<template>
  <div class="ReferenceOperatorListMain">
    <v-text-field label="Поиск" append-icon="mdi-magnify" outlined v-model="search"></v-text-field>
    <v-row class="px-2">
      <!-- <v-checkbox class="mr-2" v-model="isShowPaymentTrue" justijy-content-end :label="'Ожидание экспресса'"></v-checkbox> -->
      <!-- <v-checkbox class="mr-2" v-model="created" justijy-content-end :label="'Создан'"></v-checkbox> -->
      <!-- <v-checkbox class="mr-2" v-model="confirmByClient" justijy-content-end :label="'Подтвержден клиентом'"></v-checkbox> -->
      <v-checkbox class="mr-2" v-model="paid" justijy-content-end :label="'Оплачен'"></v-checkbox>
      <!-- <v-checkbox class="mr-2" v-model="sendToRestaurant" justijy-content-end :label="'Готовится'"></v-checkbox> -->
      <!-- <v-checkbox class="mr-2" v-model="confirmByRestaurant" justijy-content-end :label="'Принят рестораном'"></v-checkbox> -->
      <v-checkbox class="mr-2" v-model="cooking" justijy-content-end :label="'Готовится'"></v-checkbox>
      <!-- <v-checkbox class="mr-2" v-model="cooked" justijy-content-end :label="'Приготовлен'"></v-checkbox> -->
      <v-checkbox class="mr-2" v-model="waitingDelivery" justijy-content-end :label="'Готов к выдаче'"></v-checkbox>
      <!-- <v-checkbox class="mr-2" v-model="delivering" justijy-content-end :label="'Доставляется'"></v-checkbox> -->
      <!-- <v-checkbox class="mr-2" v-model="waitingPassClient" justijy-content-end :label="'Ожидает передачи клиенту'"></v-checkbox> -->
      <v-checkbox class="mr-2" v-model="confirm" justijy-content-end :label="'Завершен'"></v-checkbox>
      <v-checkbox class="mr-2" v-model="canceled" justijy-content-end :label="'Отменен'"></v-checkbox>

      <!-- <v-checkbox class="mr-2" v-model="isShowOperatorWorkDone" justijy-content-end :label="'Отправка на почту'"></v-checkbox> -->
      <!-- <v-checkbox class="mr-2" v-model="isShowWorkDone" justijy-content-end :label="'Завершены'"></v-checkbox>
      <v-checkbox class="mr-2" v-model="isCancelReference" justijy-content-end :label="'Отменены'"></v-checkbox> -->
    </v-row>
    <!-- <div v-for="panel in fitredByStatusPanels" :key="statusId"></div> -->
    <!-- <v-row>
      <v-col> </v-col>
    </v-row> -->
    <v-expansion-panels mb="3">
      <v-expansion-panel v-for="reference in filteredReferenceList" :key="reference.id">
        <v-expansion-panel-header :color="getColor(reference)">
          <v-row>
            <v-col>
              <div>
                <p class="ReferenceOperatorListTableHeader">ФИО</p>
                {{ reference.clientName }}
              </div>
            </v-col>
            <v-col>
              <div>
                <p class="ReferenceOperatorListTableHeader">Почта</p>
                {{ reference.clientEmail }}
              </div>
            </v-col>
            <v-col>
              <div>
                <p class="ReferenceOperatorListTableHeader">Телефон</p>
                {{ reference.clientPhone }}
              </div>
            </v-col>
            <v-col>
              <div>
                <p class="ReferenceOperatorListTableHeader">Заказано</p>
                {{ formatDate(reference.createdAt) }}
              </div>
            </v-col>
            <v-col>
              <div>
                <p class="ReferenceOperatorListTableHeader">Статус</p>
                {{ getStatus(reference) }}
              </div>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content :color="getColor(reference)">
          <div>Заказ № {{ reference.orderNum }}</div>
          <br />
          <div v-if="isOrderTaken(reference) !== false">Справка обрабатывается оператором {{ isOrderTaken(reference) }}</div>
          <ReferenceOperatorWork :order="reference" :type="type" :cafe="referenceList.nameRu" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import ReferenceHelper from '@/helpers/reference/reference.helper';
import ViewHelper from '@/helpers/view.helper';
import ReferenceOperatorWork from '@/components/reference/ReferenceOperatorWork';
import moment from 'moment';
// import { mapActions } from 'vuex';

export default {
  data() {
    return {
      loading: false,
      created: false,
      confirmByClient: false,
      paid: true,
      sendToRestaurant: false,
      confirmByRestaurant: false,
      cooking: true,
      cooked: false,
      waitingDelivery: true,
      delivering: false,
      waitingPassClient: false,
      confirm: false,
      canceled: false,
      // isShowCreated: false,
      // isShowPaymentFalse: false,
      // isShowPaymentTrue: true,
      // isShowWaitingOperatorWork: true,
      // isShowOperatorWorkDone: true,
      // isShowWorkDone: false,
      // isCancelReference: false,
      search: ''
    };
  },
  props: {
    type: {
      required: true
    },
    referenceList: {
      required: true
    }
  },
  components: {
    ReferenceOperatorWork
  },
  computed: {
    ordersList() {
      let orders = [];
      console.log(this.referenceList, 'ref');
      if (this.referenceList !== undefined && this.referenceList !== null && this.referenceList.length > 0) {
        orders = this.referenceList;
        console.log('orders', orders);
      }
      return orders;
    },
    // fitredByStatusPanels() {

    // }
    filteredReferenceList() {
      let modifySearch = this.search.toLowerCase();
      let filtredArray = [];
      console.log('ordersList', this.ordersList);
      if (modifySearch !== '') {
        if (this.referenceList !== undefined && this.referenceList !== null) {
          filtredArray = this.ordersList.filter((item) => {
            return (
              item.clientName.toLowerCase().indexOf(modifySearch) !== -1 ||
              item.clientEmail.indexOf(modifySearch) !== -1 ||
              item.clientPhone.indexOf(modifySearch) !== -1 ||
              item.orderNum.indexOf(modifySearch) !== -1
            );
          });
        }
      } else {
        filtredArray = this.ordersList;
        if (this.created === false) {
          filtredArray = filtredArray.filter((item) => {
            return item.status.id !== 1;
          });
        }
        if (this.confirmByClient === false) {
          filtredArray = filtredArray.filter((item) => {
            return item.status.id !== 2;
          });
        }
        if (this.paid === false) {
          filtredArray = filtredArray.filter((item) => {
            return item.status.id !== 11;
          });
        }
        if (this.sendToRestaurant === false) {
          filtredArray = filtredArray.filter((item) => {
            return item.status.id !== 21;
          });
        }
        if (this.confirmByRestaurant === false) {
          filtredArray = filtredArray.filter((item) => {
            return item.status.id !== 22;
          });
        }
        if (this.cooking === false) {
          filtredArray = filtredArray.filter((item) => {
            return item.status.id !== 23;
          });
        }
        if (this.cooked === false) {
          filtredArray = filtredArray.filter((item) => {
            return item.status.id !== 24;
          });
        }
        if (this.waitingDelivery === false) {
          filtredArray = filtredArray.filter((item) => {
            return item.status.id !== 30;
          });
        }
        if (this.delivering === false) {
          filtredArray = filtredArray.filter((item) => {
            return item.status.id !== 31;
          });
        }
        if (this.waitingPassClient === false) {
          filtredArray = filtredArray.filter((item) => {
            return item.status.id !== 40;
          });
        }
        if (this.confirm === false) {
          filtredArray = filtredArray.filter((item) => {
            return item.status.id !== 50;
          });
        }
        if (this.canceled === false) {
          filtredArray = filtredArray.filter((item) => {
            return item.status.id !== 100;
          });
        }
        // if (this.isWorkProgress === false) {
        //   filtredArray = filtredArray.filter((item) => {
        //     return this.isOrderTaken(item) === false || this.isOrderTaken(item) === this.currentUser;
        //   });
        // }
        // }
      }
      return filtredArray;
    }
    // ...mapGetters('reference', ['referenceList'])
  },
  methods: {
    getSeatName: ReferenceHelper.getSeatName,
    getCarName: ReferenceHelper.getCarName,
    getTariffName: ReferenceHelper.getTariffName,
    getStationName: ReferenceHelper.getStationName,
    getOrderStatus: ReferenceHelper.getOrderStatus,
    formatDate: ViewHelper.formatDate,
    formatDateOnly: ViewHelper.formatDateOnly,
    getReferenceTypeName: ReferenceHelper.getReferenceTypeName,
    getStatus(reference) {
      return ReferenceHelper.getOrderStatus(reference.status.id);
    },
    isOrderTaken(order) {
      if (!order || !order.reservedDate) {
        return false;
      }
      if (moment(order.reservedDate).isBefore(moment().subtract(10, 'm'))) {
        return false;
      }
      if (!order.reservedUser.fio || order.reservedUser.fio.length <= 0) {
        return '';
      }
      return order.reservedUser.fio;
    },
    getColor(item) {
      let color = '';
      if (item.currentState === 6) color = '#00FF7F20';
      else if (this.isOrderTaken(item) === false && item.currentState === 4) color = '#FFFF0020';
      else if (this.isOrderTaken(item).length > 0 && item.currentState === 4) color = '#1E90FF20';
      else if (item.currentState === 100) color = '#F1525220';
      return color;
    }
  }
};
</script>
<style scoped>
.ReferenceOperatorListMain {
  padding: 5px 5px 20px 5px;
  color: black;
}
.ReferenceOperatorListTableHeader {
  /* padding: 12px 24px 12px 12px; */
  font-weight: bold;
}
.VipListTableFio {
  font-weight: bold;
}
</style>
